<template>
  <div >
    <HeaderWindow
      @onClose="onClose"
      :title="title"
      :show_calll="true"
    />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person_disease"
        :person="person_disease && person_disease.person"
        :disease="person_disease"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar
      v-if="person_disease"
      :showing="show"
      :person_id="person_disease.person_id"
      :disease="person_disease"
    /> 
      <div class="row">
        <div class="telemedicine-management pr-3 pt-3">
          <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
            <div class="d-flex justify-content-end mb-3">
              <select
                name=""
                id=""
                v-model="status"
                class="form-select border mr-3 w-150px"
                @change="getPacs()"
              >
                <option :value="null">Trạng thái</option>
                <option :value="1">Đang upload</option>
                <option :value="2">PACS sẵn sàng</option>
                <option :value="3">Xử lý lỗi</option>
              </select>
              <select
                name=""
                id=""
                v-model="sort_by"
                class="form-select border mr-3 w-150px"
                @change="getPacs()"
              >
                <option value="updated_at">Ngày cập nhật</option>
                <option value="created_at">Ngày tạo</option>
              </select>
              <select
                name=""
                id=""
                v-model="sort_by"
                class="form-select border mr-3 w-150px"
                @change="getPacs()"
              >
                <option value="desc">Mới nhất trước</option>
                <option value="asc">Cũ nhất trước</option>
              </select>
              <div class="input-group w-250px">
                <input
                  class="h-100 border form-control"
                  type="text"
                  v-model="search"
                  @keyup.enter="onSearch()"
                  @keyup.delete="onSearchDelete()"
                >
                <button
                  class="input-group-text btn btn-light"
                  @click="onSearch()"
                >Tìm kiếm</button>
              </div>
              <div class="col-6 mb-1">
                <div class="d-flex justify-content-end">
                  <button
                    class="input-group-text btn btn-l btn-primary"
                    @click="addARecord()"
                  >Thêm mới +</button>
                </div>
              </div>
            </div>
            <table class="table table-vcenter table-mobile-md card-table txt-black">
              <thead>
                <tr>
                  <th>Accession number</th>
                  <th>Họ tên</th>
                  <th>View</th>
                  <th>Modality (Loại)</th>
                  <th>Trạng thái</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody
                v-if="pacs && pacs.data && !loading"
                class="txt-black"
              >
                <tr
                  v-for="pac in pacs.data"
                  :key="pac.id"
                >
                  <td data-title="Accession number">
                    <p
                      class="mb-0"
                      v-if="pac.accession"
                    >{{pac.accession}}</p>
                  </td>
                  <td data-title="Họ tên">
                    <span>{{pac.patient_name}}</span>
                  </td>
                  <td data-title="Xem">
                    <div v-if="pac.status === 2">
                      <a
                        href="javascript:;"
                        @click="gotoGE(pac.pac_url)"
                      >GE Viewer</a>
                    </div>
                  </td>
                  <td data-title="Modality">
                    {{pac.modality}}
                  </td>
                  <td
                    data-title="Trạng thái"
                    class="font-weight-bold"
                  >
                    <div
                      class="border-left border-4 pl-1 border-warning"
                      v-if="pac.status === 1"
                    >
                      Đang upload
                    </div>
                    <div
                      class="border-left border-4 pl-1 border-success"
                      v-if="pac.status === 2"
                    >
                      PACS sẵn sàng
                    </div>
                    <div
                      class="border-left border-4 pl-1 border-danger"
                      v-if="pac.status === 3"
                    >
                      Xử lý lỗi
                    </div>
                  </td>
                  <td data-title="Created at">
                    <span>{{formatDDMMYY(pac.created_at)}}</span>
                  </td>
                  <td data-title="Updated at">
                    <span>{{formatDDMMYY(pac.updated_at)}}</span>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-if="loading"
                class="h-100"
                style="min-height: 600px;"
              >
                <tr>
                  <td
                    colspan="6"
                    class="text-center py-5"
                  >
                    <div
                      class="spinner-border"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              v-show="pacs && pacs.data"
              :items="pacs"
              :current_page="current_page"
              @onRefresh="getPacs"
            ></Pagination>
          </div>
        </div>
      </div> 
  </div>
</template>

<script>
import PersonProfile from '../../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../../components/Cms/TypeBar.vue'
import Pagination from '../../../components/Pagination.vue'
export default {

  name: 'PacsList',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      pacs: null,
      loading: false,
      search: '',
      current_page: 1,
      person_disease: null,
      show: 'pacs',
      title: 'Danh sách Hình ảnh PACS',
      monitor_stats: null
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        self.getPersonsDiseases()
        self.getPacs()
        self.getStats()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getPacs()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    async getPacs () {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        person_diseases_id: self.$route?.params?.id,
        person_id: self.person_id,
        limit: 10,
        search: self.search,
        sort: self.sort || 'created_at',
        sort_by: self.sort_by || 'desc',
        page: self.current_page
      }
      if (self.status !== null) {
        params.status = self.status
      }
      await self.$rf.getRequest('AdminRequest').getPacs(params).then(res => {
        if (res && res.data) {
          self.pacs = res.data
        }
      })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    },
    formatDDMMYY (date) {
      return window.moment(date).format('DD/MM/YY mm:ss')
    },
    gotoGE (param) {
      window.open(param)
    },
    onSearch () {
      let self = this
      self.getPacs()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getPacs()
      }
    },
    async addARecord () {
      let self = this
      let params = {
        person_diseases_id: self.$route?.params?.id
      }
      await self.$rf.getRequest('AdminRequest').createPacs(params).then((r) => {
        self.getPacs()
      })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-add-new {
  height: 50px;
  border-radius: 0;
}
a:hover {
  text-decoration: none;
}
</style>