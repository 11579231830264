<template>
  <div class="px-5">
    <Tabbar />

    <div class="d-flex my-4">
      <select
        name=""
        id=""
        v-model="sort"
        class="p-1 mr-1 form-select px-3 border mr-3"
        @change="getVoucher()"
      >
        <option value="updated_at">Ngày cập nhật</option>
        <option value="created_at">Ngày tạo</option>
      </select>
      <select
        name=""
        id=""
        v-model="sort_by"
        class="p-1 form-select px-3 border mr-3"
        @change="getVoucher()"
      >
        <option value="desc">Mới nhất trước</option>
        <option value="asc">Cũ nhất trước</option>
      </select>
          <button
            class="input-group-text btn btn-l btn-primary"
            @click="showAdd(true)"
          >Thêm mới +</button>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Bác sĩ</th>
              <th>Tên gói</th>
              <th>Loại</th>
              <th>Code</th>
              <th>Seri</th>
              <th>Khuyến mại</th>
              <th>Hiệu lực</th>
              <th>Trạng thái</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="pack in packagesVoucher.data"
              :key="pack.id"
            >
              <td data-label="Bác sĩ">
                {{pack.doctor? pack.doctor.name : ''}}
              </td>
               <td data-label="Tên gói">
                {{!pack.member_package && !pack.member_package ? '' : pack.member_package.name && pack.member_package.name}}
              </td>
              <td data-label="Loại">
                {{pack.type === 1 ? 'FREE' : 'PREMIUM'}}
              </td>
              <td data-label="Code">
                {{pack.code}}
              </td>
              <td data-label="Seri">
                {{pack.name}}
              </td>
              <td data-label="Khuyến mại">
                <span>{{pack.months}}&nbsp;Tháng</span><br>
                <span>Giảm&nbsp;{{pack.value}}</span>
              </td>
              <td data-label="Hiệu lực">
                {{pack.expired_date}}
              </td>
              <td data-label="Trạng thái">
                {{pack.status === 1 ? 'Đã sử dụng' : 'Chưa sử dụng'}}
              </td>
               <td>

                <button
                  class="btn bg-pri bd-pri text-white mt-1 mr-2"
                  @click="update(pack)"
                >Chỉnh sửa</button>
                <button
                  class="btn mt-1"
                  :class="!pack.status ? 'bg-pri bd-pri text-white' : 'btn-white'"
                  @click="changeStatusPack(pack)"
                >{{pack.status ? 'Inactive': 'Active'}}</button>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ModalUpdateVoucher
      @onUpdate="onUpdate"
      :edit="edit"
    />
    <ModalCreateVoucher @onCreate="onCreate" />
  </div>
</template>

<script>
import Tabbar from '../../../components/Cms/Members/Tabbar.vue'
import ModalCreateVoucher from '../../../components/Cms/Members/Voucher/ModalCreateVoucher.vue'
import ModalUpdateVoucher from '../../../components/Cms/Members/Voucher/ModalUpdateVoucher.vue'
export default {
  name: 'VoucherManagement',
  components: { Tabbar, ModalCreateVoucher, ModalUpdateVoucher },
  data () {
    return {
      date: '',
      packages: [],
      packagesVoucher: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      page: 1,
      edit: null
    }
  },
  mounted () {
    let self = this
    self.getVoucher()
    this.getPackages()
  },
  methods: {
    async getVoucher () {
      let self = this
      self.loading = true
      try {
        self.page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 50,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.page
        }
        await self.$rf.getRequest('AdminRequest').getVoucher(params).then(res => {
          self.packagesVoucher = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async getPackages () {
      let self = this
      self.loading = true
      try {
        let params = {
          limit: 50,
          sort: 'created_at',
          sort_by: 'desc',
          page: 1
        }
        await self.$rf.getRequest('AdminRequest').getPackages(params).then(res => {
          self.packages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onSearch () {
      let self = this
      self.getVoucher()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getVoucher()
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.loading = true
        self.$rf.getRequest('AdminRequest').createVoucher(data).then((resp) => {
          self.showAdd(false)
          self.getVoucher()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    update (pack) {
      this.edit = pack
      this.showEdit(true)
    },
    async onUpdate (data) {
      let self = this
      if (data && self.edit) {
        self.loading = true
        self.$rf.getRequest('AdminRequest').updateVoucher(self.edit.id, data).then((resp) => {
          self.edit = null
          self.showEdit(false)
          self.getVoucher()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    async changeStatusPack (pack) {
      let self = this
      if (pack) {
        self.loading = true
        let params = {
          code: pack.code,
          name: pack.name,
          value: pack.value,
          months: pack.months,
          type: pack.type,
          status: parseInt(pack.status) ? 0 : 1,
          expired_date: pack.expired_date
        }
        self.$rf.getRequest('AdminRequest').updateVoucher(pack.id, params).then((resp) => {
          self.getVoucher()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    showEdit (show) {
      window.$('#ModalUpdateVoucher').modal(show ? 'show' : 'hide')
    },
    showAdd (show) {
      window.$('#ModalCreateVoucher').modal(show ? 'show' : 'hide')
    },
    fontmatDateDDMMYYYY () {
      var d = new Date()
      d.getDay()
      d.getFullYear()
      d.getYear()
      d.getHours()
      d.getMilliseconds()
      d.getMinutes()
      d.getSeconds()
      d.getTime()
    }
  }
}
</script>

<style scoped>
</style>