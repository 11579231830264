<template>
  <div class="px-5">
    <Tabbar />

    <div class="d-flex my-4">
      <select
        name=""
        id=""
        v-model="sort"
        class="p-1 mr-1 form-select px-3 border mr-3"
        @change="getPackageBenefits()"
      >
        <option value="updated_at">Gói</option>
        <option value="created_at">Ngày tạo</option>
      </select>
      <select
        name=""
        id=""
        v-model="sort_by"
        class="p-1 form-select px-3 border mr-3"
        @change="getPackageBenefits()"
      >
        <option value="desc">Mới nhất</option>
        <option value="asc">Cũ nhất</option>
      </select>
          <button
            class="input-group-text btn btn-l btn-primary"
            @click="showAdd(true)"
          >Thêm mới +</button>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Chi tiết lợi ích</th>
              <th>Tên gói</th>
              <th>Mã</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="pack in packagesBenefit.data"
              :key="pack.id"
            >
              <td data-label="Chi tiết lợi ích">
                {{pack.content}}
              </td>
              <td data-label="Tên gói">
                {{pack.member_package.name && pack.member_package.name}}
              </td>
              <td data-label="Mã">
                {{pack.member_package.code && pack.member_package.code}}
              </td>
              <!-- <td>

                <button
                  class="btn bg-pri bd-pri text-white mt-1 mr-2"
                  @click="onDelete(pack.id)"
                >Xóa</button>
              </td> -->
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ModalCreateBenefit @onCreate="onCreate" />
  </div>
</template>

<script>
import Tabbar from '../../../components/Cms/Members/Tabbar.vue'
import ModalCreateBenefit from '../../../components/Cms/Members/Benefit/ModalCreateBenefit.vue'
export default {
  name: 'MemberPackageBenefit',
  components: { Tabbar, ModalCreateBenefit },
  data () {
    return {
      packagesBenefit: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      page: 1,
      edit: null
    }
  },
  mounted () {
    let self = this
    self.getPackageBenefits()
  },
  methods: {
    async getPackageBenefits () {
      let self = this
      self.loading = true
      try {
        self.page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.page
        }
        await self.$rf.getRequest('AdminRequest').getPackageBenefits(params).then(res => {
          self.packagesBenefit = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    // async onDelete (id) {
    //   let self = this
    //   let r = confirm('Bạn có chắc chắn muốn xóa lợi ích này')
    //   if (!r) return
    //   if (id) {
    //     self.$rf.getRequest('AdminRequest').deleteBenefit(id).then((resp) => {
    //       self.getPackageBenefits()
    //     })
    //   }
    // },
    onSearch () {
      let self = this
      self.getPackageBenefits()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getPackageBenefits()
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.loading = true
        self.$rf.getRequest('AdminRequest').createPackagesBenefit(data).then((resp) => {
          self.showAdd(false)
          self.getPackageBenefits()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    showAdd (show) {
      window.$('#ModalCreateBenefit').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
</style>