<template>
  <div class="px-5">
    <Tabbar />

    <div class="d-flex my-4">
      <select
        name=""
        id=""
        v-model="sort"
        class="p-1 mr-1 form-select px-3 border mr-3"
        @change="getComboPackages()"
      >
        <option value="updated_at">Gói</option>
        <option value="created_at">Ngày tạo</option>
      </select>
      <select
        name=""
        id=""
        v-model="sort_by"
        class="p-1 form-select px-3 border mr-3"
        @change="getComboPackages()"
      >
        <option value="desc">Mới nhất</option>
        <option value="asc">Cũ nhất</option>
      </select>
          <button
            class="input-group-text btn btn-l btn-primary"
            @click="showAdd(true)"
          >Thêm mới +</button>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Tên gói</th>
              <th>Giá tháng</th>
              <th>Số tháng Bán</th>
              <th>Số tháng khuyến mãi</th>
              <th>Giá combo</th>
              <th>Mô tả</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="pack in comboPackages.data"
              :key="pack.id"
            >
              <td data-label="Tên gói">
                {{pack.member_package && pack.member_package.name}}
              </td>
              <td data-label="Giá tháng">
                {{pack.package_price}}
              </td>
              <td data-label="Số tháng bán">
                {{pack.package_months}}
              </td>
              <td data-label="Số tháng khuyến mãi">
                {{pack.combo_months}}
              </td>
              <td data-label="Giá combo">
                {{pack.combo_price}}
              </td>
              <td data-label="Mô tả">
                {{pack.description}}
              </td>
              <td>

                <button
                  class="btn bg-pri bd-pri text-white mt-1 mr-2"
                  @click="update(pack)"
                >Chỉnh sửa</button>
                <button
                  class="btn mt-1"
                  :class="!pack.status ? 'bg-pri bd-pri text-white' : 'btn-white'"
                  @click="changeStatusPack(pack)"
                >{{pack.status ? 'Inactive' : 'Active'}}</button>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ModalDoEdit
      @onUpdate="onUpdate"
      :edit="edit"
    />
    <ModalDoCreate @onCreate="onCreate" />
  </div>
</template>

<script>
import Tabbar from '../../../components/Cms/Members/Tabbar.vue'
import ModalDoCreate from '../../../components/Cms/Members/Combo/ModalDoCreate.vue'
import ModalDoEdit from '../../../components/Cms/Members/Combo/ModalDoEdit.vue'
export default {
  name: 'ComboManagement',
  components: { Tabbar, ModalDoCreate, ModalDoEdit },
  data () {
    return {
      comboPackages: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      page: 1,
      edit: null
    }
  },
  mounted () {
    let self = this
    self.getComboPackages()
  },
  methods: {
    async getComboPackages () {
      let self = this
      self.loading = true
      try {
        self.page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.page
        }
        await self.$rf.getRequest('AdminRequest').getComboPackages(params).then(res => {
          self.comboPackages = res.data || []
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onSearch () {
      let self = this
      self.getComboPackages()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getComboPackages()
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.loading = true
        self.$rf.getRequest('AdminRequest').createComboPackages(data).then((resp) => {
          self.showAdd(false)
          self.getComboPackages()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    update (pack) {
      this.edit = pack
      this.showEdit(true)
    },
    async onUpdate (data) {
      let self = this
      if (data && self.edit) {
        self.loading = true
        self.$rf.getRequest('AdminRequest').updateComboPackages(self.edit.id, data).then((resp) => {
          self.edit = null
          self.showEdit(false)
          self.getComboPackages()
        }).catch((e) => {
          console.log(e)
          console.log(e.message)
        }).finally(() => {
          self.loading = false
        })
      }
    },
    async changeStatusPack (pack) {
      let self = this
      if (pack) {
        self.loading = true
        let params = {
          package_months: pack.package_months,
          package_price: pack.package_price,
          combo_months: pack.combo_months,
          combo_price: pack.combo_price,
          status: parseInt(pack.status) ? 0 : 1,
          description: pack.description
        }
        self.$rf.getRequest('AdminRequest').updateComboPackages(pack.id, params).then((resp) => {
          self.getComboPackages()
        }).finally(() => {
          self.loading = false
        })
      }
    },
    showEdit (show) {
      window.$('#ModalDoEdit').modal(show ? 'show' : 'hide')
    },
    showAdd (show) {
      window.$('#ModalDoCreate').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
</style>