<template>
  <div>

    <div class="row">
      <div class="telemedicine-management pr-3 pt-3">
        <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
          <div class="d-flex justify-content-end mb-3">
            <select
              name=""
              id=""
              v-model="status"
              class="form-select border mr-3 w-170px"
              @change="getPacs()"
            >
              <option :value="null">Trạng thái</option>
              <option :value="1">Đang upload</option>
              <option :value="2">PACS sẵn sàng</option>
              <option :value="3">Xử lý lỗi</option>
            </select>
            <select
              name=""
              id=""
              v-model="sort"
              class="form-select border mr-3 w-170px"
              @change="getPacs()"
            >
              <option value="updated_at">Ngày cập nhật</option>
              <option value="created_at">Ngày tạo</option>
            </select>
            <select
              name=""
              id=""
              v-model="sort_by"
              class="form-select border mr-3 w-170px"
              @change="getPacs()"
            >
              <option value="desc">Mới nhất trước</option>
              <option value="asc">Cũ nhất trước</option>
            </select>
            <div class="input-group mr-2">
              <input
                class="h-100 border form-control"
                type="text"
                v-model="search"
                @keyup.enter="onSearch()"
                @keyup.delete="onSearchDelete()"
              >
              <button
                class="input-group-text btn btn-light"
                @click="onSearch()"
              >Tìm kiếm</button>
            </div>
            <button
              class="input-group-text btn btn-l btn-primary"
              @click="showAdd(true)"
            >Thêm mới +</button>
          </div>
          <table class="table table-vcenter table-mobile-md card-table txt-black">
            <thead>
              <tr>
                <th>Accession number</th>
                <th>Họ tên</th>
                <th>HSSK</th>
                <th>View</th>
                <th>Modality (Loại)</th>
                <th>File</th>
                <th>Trạng thái</th>
                <th>Created at</th>
                <th>Updated at</th>
              </tr>
            </thead>
            <tbody
              v-if="pacs && pacs.data && !loading"
              class="txt-black"
            >
              <tr
                v-for="pac in pacs.data"
                :key="pac.id"
              >
                <td data-title="Accession number">
                  <p
                    class="mb-0"
                    v-if="pac.accession"
                  >{{pac.accession}}</p>
                </td>
                <td data-title="Họ tên">
                  <span>{{pac.patient_name}}</span>
                </td>
                <td data-title="HSSK">
                  <button
                    class="btn-m bg-pri bd-pri text-white"
                    @click="getHssk(pac.person_diseases_id)"
                  >Xem chi tiết</button>
                </td>
                <td data-title="Xem">
                  <div v-if="pac.status === 2">
                    <button
                      class="btn-m bg-pri bd-pri text-white"
                      @click="getUrl(pac.accession)"
                    >GE Viewer</button>
                  </div>
                </td>
                <td data-title="Modality">
                  {{pac.modality}}
                </td>
                <td data-title="File">
                  <button
                    class="btn-m bg-pri bd-pri text-white"
                    v-if="pac.files"
                    @click="getLinkDownloadAws(pac)"
                  >Download</button>
                </td>
                <td
                  data-title="Trạng thái"
                  class="font-weight-bold"
                >
                  <div
                    class="border-left border-4 pl-1 border-warning"
                    v-if="pac.status === 1"
                  >
                    Đang upload
                  </div>
                  <div
                    class="border-left border-4 pl-1 border-success"
                    v-if="pac.status === 2"
                  >
                    PACS sẵn sàng
                  </div>
                  <div
                    class="border-left border-4 pl-1 border-danger"
                    v-if="pac.status === 3"
                  >
                    Xử lý lỗi
                  </div>
                </td>
                <td data-title="Created at">
                  <span>{{formatDDMMYY(pac.created_at)}}</span>
                </td>
                <td data-title="Updated at">
                  <span>{{formatDDMMYY(pac.updated_at)}}</span>
                </td>
              </tr>
            </tbody>
            <tbody
              v-if="loading"
              class="h-100"
              style="min-height: 600px;"
            >
              <tr>
                <td
                  colspan="6"
                  class="text-center py-5"
                >
                  <div
                    class="spinner-border"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-show="pacs && pacs.data"
            :items="pacs"
            :current_page="current_page"
            @onRefresh="getPacs"
          ></Pagination>
        </div>
      </div>
    </div>
    <ModalAdminAddPac @addARecord="addARecord" />
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination.vue'
import ModalAdminAddPac from '../../../components/Pacs/ModalAdminAddPac.vue'
export default {
  name: 'ListPacs',
  components: { Pagination, ModalAdminAddPac },
  data () {
    return {
      pacs: null,
      loading: false,
      search: '',
      current_page: 1,
      person_disease: null,
      show: 'pacs',
      title: 'Danh sách Hình ảnh PACS',
      monitor_stats: null,
      sort: 'updated_at',
      sort_by: 'desc',
      status: null
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        let self = this
        if (!t) return
        self.getPacs()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.getPacs()
  },
  methods: {
    async getPacs () {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        limit: 10,
        search: self.search,
        sort: self.sort || 'updated_at',
        sort_by: self.sort_by || 'desc',
        page: self.current_page
      }
      if (self.status !== null) {
        params.status = self.status
      }
      await self.$rf.getRequest('AdminRequest').getPacs(params).then(res => {
        if (res && res.data) {
          self.pacs = res.data
        }
      })
    },
    async getUrl (accession) {
      let self = this
      let params = {
        accession: accession
      }
      await self.$rf.getRequest('AdminRequest').getUrlPac(params).then((r) => {
        if (r.data) {
          window.open(r.data)
        }
      })
    },
    // async getPersonsDiseases () {
    //   let self = this
    //   try {
    //     await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
    //       if (res && res.data) {
    //         self.person_disease = res.data
    //       }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    formatDDMMYY (date) {
      return window.moment(date).format('DD/MM/YY mm:ss')
    },
    getHssk (id) {
      window.open('#/admin/diseases/' + id + '/pacs')
    },
    onSearch () {
      let self = this
      self.getPacs()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getPacs()
      }
    },
    showAdd (show) {
      window.$('#modalAdminAddPac').modal(show ? 'show' : 'hide')
    },
    async addARecord (disease) {
      let self = this
      if (!disease) return
      let params = {
        person_diseases_id: disease.id
      }
      await self.$rf.getRequest('AdminRequest').createPacs(params).then((r) => {
        self.getPacs()
        self.showAdd()
      })
    },
    async getLinkDownloadAws (pac) {
      if (pac?.file) {
        this.callGetLinkDownloadAwsRc(pac?.file?.file_name)
      } else {
        this.callGetLinkDownloadAws(pac.files)
      }
    },
    async callGetLinkDownloadAws (name) {
      const self = this
      const params = {
        fileName: name
      }
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf.getRequest('AuthRequest').getLinkDownloadAws(params, header, false).then((r) => {
        if (r?.data?.data) window.open(r.data.data)
      }).catch((e) => {
        self.$toast.open({
          message: 'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống',
          type: 'warning'
          // all other options
        })
        console.error(e)
      })
    },
    async callGetLinkDownloadAwsRc (name) {
      const self = this
      const params = {
        fileName: name
      }
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf.getRequest('AuthRequest').getLinkDownloadAwsRc(params, header, false).then((r) => {
        if (r?.data?.data) window.open(r.data.data)
      }).catch((e) => {
        self.$toast.open({
          message: 'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống',
          type: 'warning'
          // all other options
        })
        console.error(e)
      })
    }
  }
}
</script>

<style scoped>
.w-170px {
  width: 170px;
}
</style>