<template>
  <div class="row">
    <div class="telemedicine-management pr-3 pt-3">
      <div class="row">
        <div class="col-12 mb-3">
          <Tabbar />
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="status"
            class="form-select border mr-3 w-150px"
            @change="getOrders()"
          >
            <option :value="null">Trạng thái</option>
            <option :value="1">Mới</option>
            <option :value="2">Đã thanh toán</option>
            <option :value="0">Đã hủy</option>
          </select>
          <select
            name=""
            id=""
            v-model="sort"
            class="form-select border mr-3 w-150px"
            @change="getOrders()"
          >
            <option value="updated_at">Ngày cập nhật</option>
            <option value="created_at">Ngày tạo</option>
          </select>
          <select
            name=""
            id=""
            v-model="sort_by"
            class="form-select border mr-3 w-150px"
            @change="getOrders()"
          >
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
          <div class="input-group w-250px">
            <input
              class="h-100 border form-control"
              type="text"
              v-model="search"
              @keyup.enter="onSearch()"
              @keyup.delete="onSearchDelete()"
            >
            <button
              class="input-group-text btn btn-light"
              @click="onSearch()"
            >Tìm kiếm</button>
          </div>
        </div>
        <table class="table table-vcenter table-mobile-md card-table txt-black">
          <thead>
            <tr>
              <th>Khách hàng</th>
              <th>Gói</th>
              <th>Tổng tiền</th>
              <th>Mã giảm giá</th>
              <th>Ngày tạo</th>
              <th>Mã đơn hàng</th>
              <th>Mã thanh toán</th>
              <th>Trạng thái</th>
              <th>Ghi chú</th>
              <th></th>
            </tr>
          </thead>
          <tbody
            v-if="orders && orders.data && !loading"
            class="txt-black"
          >
            <tr
              v-for="order in orders.data"
              :key="order.id"
            >
              <td data-title="Khách hàng">
                <p class="mb-0" v-if="order.user">{{order.user.name}}</p>
                <p class="mb-0" v-if="order.user.username">{{order.user.username}}</p>
              </td>
              <td data-title="Gói">
                <p
                  class="mb-0"
                  v-if="order.member_package"
                >{{order.member_package.name}}</p>
                <p class="mb-0">{{order.months}} Tháng</p>
              </td>
              <td
                data-title="Tổng tiền"
                class="font-weight-bold"
              >
                <span>{{order.total_price ? formatPrice(order.total_price) : 0}} VNĐ</span>
              </td>
              <td data-title="Mã giảm giá">
                <p class="mb-0" v-if="order.voucher">{{order.voucher.code}}</p>
                <p class="mb-0">{{order.voucher_value  ? formatPrice(order.voucher_value) + ' VNĐ' : ''}}</p>
              </td>
              <td data-title="Ngày tạo">
                <span>{{formatDDMMYY(order.created_at)}}</span>
              </td>
              <td data-title="Mã đơn hàng">
                <span>{{order.code}}</span>
              </td>
              <td
                data-title="Mã thanh toán"
                class="font-weight-bold"
              >
                <div>
                  <p
                    v-for="tran in order.transactions"
                    :key="tran.id"
                  >{{tran.code}}</p>
                </div>
              </td>
              <td
                data-title="Trạng thái"
                class="font-weight-bold"
              >

                <div
                  class="border-left border-4 pl-1 border-danger"
                  v-if="order.status === 0"
                >
                  Đã hủy
                </div>

                <div
                  class="border-left border-4 pl-1 border-warning"
                  v-if="order.status === 1"
                >
                  Mới
                </div>

                <div
                  class="border-left border-4 pl-1 border-success"
                  v-if="order.status === 2"
                >
                  Đã thanh toán
                </div>

                <div
                  class="border-left border-4 pl-1 border-primary"
                  v-if="order.status === 3"
                >
                  Xác nhận thanh toán
                </div>
              </td>
              <td>
                <div class="order-note">
                  {{order.last_note ? order.last_note.notes : ''}}
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center actions">
                  <button
                    class="btn bg-pri bd-pri text-white mr-2 mb-1 h-100"
                    :class="order.status != 2 ? 'disabled' : ''"
                    @click="onConfirmlOrder(order)"
                  >Xác nhận thanh toán</button>
                  <!-- <button
                  class="btn btn-sm mr-2"
                  :class="order.status ? 'btn-ghost' : 'bg-pri bd-pri text-white'"
                  @click="onUpdateStatus(order.id)"
                >{{order.status ? 'Ẩn' : 'Hiện'}}</button> -->
                  <a
                    class="mr-2 mb-1 cursor-pointer"
                    @click="openNotes(order.id)"
                    data-toggle="tooltip"
                    title="Ghi chú"
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.8 8.21048C21.39 7.80048 20.68 8.08048 20.68 8.65048V12.1405C20.68 13.6005 21.92 14.8105 23.43 14.8105C24.38 14.8205 25.7 14.8205 26.83 14.8205C27.4 14.8205 27.7 14.1505 27.3 13.7505C25.86 12.3005 23.28 9.69048 21.8 8.21048Z"
                        fill="#20419B"
                      />
                      <path
                        d="M26.5 16.19H23.61C21.24 16.19 19.31 14.26 19.31 11.89V9C19.31 8.45 18.86 8 18.31 8H14.07C10.99 8 8.5 10 8.5 13.57V22.43C8.5 26 10.99 28 14.07 28H21.93C25.01 28 27.5 26 27.5 22.43V17.19C27.5 16.64 27.05 16.19 26.5 16.19ZM17.5 23.75H13.5C13.09 23.75 12.75 23.41 12.75 23C12.75 22.59 13.09 22.25 13.5 22.25H17.5C17.91 22.25 18.25 22.59 18.25 23C18.25 23.41 17.91 23.75 17.5 23.75ZM19.5 19.75H13.5C13.09 19.75 12.75 19.41 12.75 19C12.75 18.59 13.09 18.25 13.5 18.25H19.5C19.91 18.25 20.25 18.59 20.25 19C20.25 19.41 19.91 19.75 19.5 19.75Z"
                        fill="#20419B"
                      />
                      <rect
                        x="0.75"
                        y="0.75"
                        width="34.5"
                        height="34.5"
                        rx="6.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </a>
                  <a
                    class="mb-1 cursor-pointer"
                    @click="onCancelOrder(order)"
                    data-toggle="tooltip"
                    :class="order.status == 3 ? 'disabled' : ''"
                    title="Hủy đơn hàng"
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.5 18.5H25C23.9 18.5 23 19.4 23 20.5C23 21.6 23.9 22.5 25 22.5H27.5C27.78 22.5 28 22.28 28 22V19C28 18.72 27.78 18.5 27.5 18.5Z"
                        :fill="order.status == 3 ? '#B6C2CF' : '#DC0101'"
                      />
                      <path
                        d="M14 17C12.5 17 11.1625 17.6625 10.25 18.7C9.475 19.5875 9 20.7375 9 22C9 22.9375 9.2625 23.825 9.725 24.575C10.5875 26.025 12.175 27 14 27C15.2625 27 16.4125 26.5375 17.2875 25.75C17.675 25.425 18.0125 25.025 18.275 24.575C18.7375 23.825 19 22.9375 19 22C19 19.2375 16.7625 17 14 17ZM16 23.975C15.8125 24.1625 15.575 24.25 15.3375 24.25C15.1 24.25 14.8625 24.1625 14.675 23.975L14.0125 23.3125L13.325 24C13.1375 24.1875 12.9 24.275 12.6625 24.275C12.425 24.275 12.1875 24.1875 12 24C11.6375 23.6375 11.6375 23.0375 12 22.675L12.6875 21.9875L12.025 21.325C11.6625 20.9625 11.6625 20.3625 12.025 20C12.3875 19.6375 12.9875 19.6375 13.35 20L14.0125 20.6625L14.6375 20.0375C15 19.675 15.6 19.675 15.9625 20.0375C16.325 20.4 16.325 21 15.9625 21.3625L15.3375 21.9875L16 22.65C16.3625 23.0125 16.3625 23.6 16 23.975Z"
                        :fill="order.status == 3 ? '#B6C2CF' : '#DC0101'"
                      />
                      <path
                        d="M22.53 11.4002C22.83 11.6902 22.58 12.1402 22.16 12.1402L13.88 12.1302C13.4 12.1302 13.16 11.5502 13.5 11.2102L15.25 9.45016C16.73 7.98016 19.12 7.98016 20.6 9.45016L22.49 11.3602C22.5 11.3702 22.52 11.3902 22.53 11.4002Z"
                        :fill="order.status == 3 ? '#B6C2CF' : '#DC0101'"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M23.1 28C25.5 28 27.26 26.72 27.87 24.66C27.97 24.34 27.74 24 27.39 24H25.2C23.53 24 22.01 22.87 21.68 21.23C21.21 18.98 22.93 17 25.1 17H27.39C27.73 17 27.97 16.67 27.87 16.34C27.33 14.51 25.87 13.28 23.85 13.05C23.61 13.01 23.36 13 23.1 13H13.1C12.82 13 12.55 13.02 12.3 13.06C9.74999 13.38 8.10999 15.28 8.10999 18V19.47C8.10999 19.6672 8.21939 19.828 8.37467 19.9211C8.63402 19.2264 9.01882 18.591 9.5 18.04C10.595 16.795 12.2 16 14 16C17.315 16 20 18.685 20 22C20 23.125 19.685 24.19 19.13 25.09C18.815 25.63 18.41 26.11 17.945 26.5C17.4077 26.9836 16.784 27.365 16.1 27.6222C16.1696 27.8342 16.3578 28 16.6 28H23.1Z"
                        :fill="order.status == 3 ? '#B6C2CF' : '#DC0101'"
                      />
                      <rect
                        x="0.75"
                        y="0.75"
                        width="34.5"
                        height="34.5"
                        rx="6.25"
                        :stroke="order.status == 3 ? '#B6C2CF' : '#DC0101'"
                        stroke-width="1.5"
                      />
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="Content"
          v-show="orders && orders.count"
          v-if="orders"
          :items="orders"
          :current_page="current_page"
          @onRefresh="getOrders"
        ></Pagination>
      </div>
    </div>
    <ModalOrderNotes :order_id="order_id" />
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination'
import Tabbar from '../../../components/Cms/Orders/Tabbar.vue'
import ModalOrderNotes from '../../../components/Cms/Orders/ModalOrderNotes.vue'
export default {
  name: 'MemberPackOrders',
  components: { Pagination, Tabbar, ModalOrderNotes },
  data () {
    return {
      orders: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      current_page: 1,
      edit: null,
      status: null,
      order_id: null
    }
  },
  mounted () {
    let self = this
    self.getOrders()
    this.$nextTick(function () {
      window.$('#modal--order-notes').on('hiden.bs.modal', function (e) {
        this.order_id = null
      })
    })
  },
  methods: {
    goToDetail (order) {
      this.$router.push({ path: `/admin/diseases-templates/${order.id}/show` })
    },
    async getOrders () {
      let self = this
      self.loading = true
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.current_page
        }
        if (self.status !== null) {
          params.status = self.status
        }
        await self.$rf.getRequest('AdminRequest').getPackageOrders(params).then(res => {
          if (res && res.data) {
            self.orders = res.data
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onUpdateStatus (id) {
      let self = this
      let r = confirm('Bạn có chắc chắn muốn ẩn chỉ số này khỏi danh sách chọn? Chỉ số này sẽ vẫn hiển thị tại các nơi đã được sử dụng.')
      if (!r) return
      if (id) {
        self.$rf.getRequest('AdminRequest').updateSurvivalStatStatus(id).then((resp) => {
          self.getOrders()
        })
      }
    },
    async onDelete (id) {
      let self = this
      let r = confirm('Bạn có chắc chắn muốn xóa chỉ số này khỏi danh sách chọn? Chỉ số này sẽ không thể xóa được nếu đã được sử dụng.')
      if (!r) return
      if (id) {
        self.$rf.getRequest('AdminRequest').deleteSurvivalStats(id).then((resp) => {
          self.getOrders()
        })
      }
    },
    onCancelOrder (order) {
      if (!order) return
      let r = prompt('Vui lòng nhập lý do hủy!')
      if (!r) return
      this.onSubmitNote(order.id, r)
      this.changeStatusPackageOrder(order, 0)
    },
    onConfirmlOrder (order) {
      if (!order) return
      let r = confirm('Xác nhận khách hàng đã thanh toán? Hành động này sẽ không thể hoàn tác!')
      if (!r) return
      this.changeStatusPackageOrder(order, 3)
    },
    async changeStatusPackageOrder (order, stt) {
      let self = this
      if (order) {
        let params = {
          id: order.id,
          code: order.code,
          status: stt
        }
        self.$rf.getRequest('AdminRequest').changeStatusPackageOrder(params).then((resp) => {
          self.getOrders()
        })
      }
    },
    async onSubmitNote (id, string) {
      let self = this
      if (id && string) {
        let param = {
          notes: 'Lý do hủy đơn hàng: ' + string
        }
        await self.$rf.getRequest('AdminRequest').createPackageOrderNotes(id, param).catch((e) => {
          console.log(e)
        })
      }
    },
    onSearch () {
      let self = this
      self.getOrders()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getOrders()
      }
    },
    formatDDMMYY (date) {
      return window.moment(date).format('DD/MM/YY')
    },
    onEdit (order) {
      this.edit = order
      this.showEdit(true)
    },
    showEdit (show) {
      window.$('#ModalUpdateStats').modal(show ? 'show' : 'hide')
    },
    showAdd (show) {
      window.$('#ModalCreateStats').modal(show ? 'show' : 'hide')
    },
    openNotes (id) {
      let self = this
      self.order_id = id
      setTimeout(() => {
        self.showOrderNotes(true)
      }, 100)
    },
    showOrderNotes (show) {
      window.$('#modal--order-notes').modal(show ? 'show' : 'hide')
    },
    formatPrice (num) {
      if (!num) return 'Liên hệ'
      return num.toLocaleString('vi')
    }
  }
}
</script>

<style scoped>
.w-150px {
  width: 150px !important;
}
.w-250px {
  width: 250px !important;
}
.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}
.order-note {
  width: 150px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
}
.actions {
width: 270px;
}
</style>