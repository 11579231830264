<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12 mb-3">
					<Tabbar/>
				</div>
				<div class="col-sm-12">
					<div class="row">
						<div class="col-12 col-lg-6 mb-1">
							<div class="row">
								<div class="col-auto">
									<div class="btn-group" role="group" aria-label="Basic example">
										<button type="button" class="btn h-100" :class="type === 0 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(0)">Tất cả</button>
										<button type="button" class="btn h-100" :class="type === 1 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(1)">Mới</button>
										<button type="button" class="btn h-100" :class="type === 2 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(2)">Đang xử lý</button>
										<button type="button" class="btn h-100" :class="type === 3 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(3)">Đã xử lý</button>
                    					<button type="button" class="btn h-100" :class="type === 4 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(4)">Đã hủy</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6 mb-1">
							<div align="right" class="row">
								<div class="col-sm-6">
									<input type="text" class="form-control bg-gray" style="height: 35px" v-model="search" @click.enter="searchOrder()" @click.delete="searchOrderDelete()">
								</div>
								<div class="col-sm-6"><button class="btn bg-pri bd-pri text-white w-100" style="font-size: 18px; font-weight: 700;" @click="searchOrder()">Tìm kiếm</button></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-2 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Service ID</th>
								<th>Bệnh nhân</th>
								<th>Tên dịch vụ</th>
								<th>Mô tả ngắn</th>
								<th>CSYT</th>
								<th>Giá tiền</th>
								<th>Giảm giá</th>
								<th>Trạng thái</th>
								<th>Ngày đặt</th>
								<th>Ghi chú</th>
							</tr>
						</thead>
						<tbody v-if="orders && orders.count" class="h-100">
							<tr class="table-tr mt-2" v-for="(order, index) in orders.data" :key="index">
								<td data-label="Service ID">
									<span class="ml-2">{{order && order.id}}</span>
								</td>
								<td data-label="Bệnh nhân">
									{{order.name ? order.name : order.person ? order.person.name : ''}}<br>
									<small class="text-black-50">{{order.phone ? order.phone : order.person ? order.person.phone : ''}}</small>
								</td>
								<td data-label="Tên dịch vụ">
									{{order && order.service && order.service.name}}
								</td>
								<td data-label="Mô tả ngắn">
									{{order && order.service && order.service.desc_short}}
								</td>
								<td data-label="CSYT">
									{{order && order.service && order.service.clinic && order.service.clinic.name}}
								</td>
								<td data-label="Giá tiền">
									{{order && order.price ? formatPrice(order.price) : ''}} {{order.currency || 'VNĐ'}}
								</td>
								<td data-label="Giảm giá">
									{{order && order.discount ? formatPrice(order.discount) : ''}}
								</td>
								<td data-label="Trạng thái">
									<select class="form-select" :class="getApptStatus(order.status)" v-model="order.status" @change="changeStatusOrder(order.id, order.status)">
										<option class="text--yellow bg--yellow-corl" value="1">Mới</option>
										<option class="text-white bg-info" value="2">Đang xử lý</option>
										<option class="text--green bg--green-corl" value="3">Đã hoàn thành</option>
										<option class="text--red bg--red-corl" value="4">Huỷ</option>
									</select>
								</td>
								<td data-label="Ngày đặt">
									{{order && order.created_at ? formatTimeDDMMYYYY(order.created_at) : ''}}
								</td>
								<td data-label="Ghi chú" class="text-truncate">
									<button class="btn btn-primary btn-sm mt-1" @click="gotoNotes(order && order.id)">Note</button>
								</td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="orders && orders.count" :items="orders" :current_page="current_page" @onRefresh="getOrders"></Pagination>
				</div>
				<div v-if="orders && !orders.count" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">Không có yêu cầu nào</p>
					</div>
				</div>
			</div>
		</div>
		<ModalOrderNotes :id_note="id_note"></ModalOrderNotes>
	</div>
</template>
<script>
  // import appUtils from '../../utils/appUtils'
  import Pagination from '../../../components/Pagination'
  import ModalOrderNotes from '../../../components/Cms/ModalOrderNotes.vue'
  import Tabbar from '../../../components/Cms/Orders/Tabbar.vue'
  export default {
    name: 'ServicesOrders',
    components: {Pagination, ModalOrderNotes, Tabbar},
    data () {
      return {
        type: 0,
        orders: null,
        current_page: 1,
        search: '',
        id_note: ''
      }
    },
    mounted () {
      let self = this
      self.getOrders()
    },
    methods: {
      formatTimeDDMMYYYY (datetime) {
        return window.moment(datetime).format('DD-MM-YYYY')
      },
      formatPrice (price) {
        return price.toLocaleString('vi')
      },
      async getOrders () {
        let self = this
        try {
          self.current_page = parseInt(self.$route.query.page) || 1
          var params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page,
            status: self.status
          }
          var res = await self.$rf.getRequest('AdminRequest').getOrders(params)
          if (res && res.data) {
            self.orders = res.data
          }
        } catch (e) {
          console.log(e)
        }
      },
      async changeStatusOrder (id, status) {
        let self = this
        try {
          var r = confirm('Bạn có chắc chắn muốn thay đổi trạng thái?')
          if (r) {
            var params = {
              status: status
            }
            await self.$rf.getRequest('AdminRequest').putOrderStatus(id, params).then(res => {
              self.getOrders()
            })
          }
        } catch (e) {
          console.log(e)
        }
      },
      changeType (type) {
        let self = this
        self.type = type
        if (type !== 0) {
          self.status = type
        } else {
          self.status = null
        }
        self.getOrders()
      },
      searchOrder () {
        let self = this
        self.getOrders()
      },
      searchOrderDelete () {
        let self = this
        if (!self.search) {
          self.getOrders()
        }
      },
      showPersondiseasesNotes (show) {
        window.$('#modal--order-notes').modal(show ? 'show' : 'hide')
      },
      gotoNotes (id) {
        let self = this
        self.id_note = id
        self.showPersondiseasesNotes(true)
      },
      getApptStatus (stt) {
        let statuses = []
        statuses[1] = 'text--yellow bg--yellow-corl'
        statuses[2] = 'text-white bg-info'
        statuses[3] = 'text--green bg--green-corl'
        statuses[4] = 'text--bg bg--bg-corl'
        return statuses[stt] ? statuses[stt] : statuses[1]
      }
    }
  }
</script>
<style scoped>
</style>