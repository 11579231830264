<template>
  <div class="row">
    <NewNavbar
      :name="'Loại dịch vụ'"
      :type="'category'"
    />
    <div class="telemedicine-management pr-3 mt-3">
      <div class="row pl-3">
        <div class="col-sm-12">
          <div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
                <input
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Nhập tên dịch vụ cần tìm kiếm"
                  aria-label="Search"
                  v-model="search"
                  @click.enter="getNewCategory()"
                  @click.delete="()=> {
                    search = ''
                    getNewCategory()
                  }"
                >
                <button
                  class="btn bg-pri bd-pri text-white my-2 my-sm-0"
                  @click="getNewCategory()"
                >Tìm kiếm</button>
              </div>
            </div>
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex h-100">
                <button
                  class="btn h-100 bg-pri bd-pri text-white my-2 my-sm-0 btn-icon-add-sm"
                  @click="onOpenCreate()"
                >Thêm mới</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table">
            <thead>
              <tr>
                <th class="pl-3">Tên loại</th>
                <th>Trạng thái</th>
                <th>Ưu tiên</th>
              </tr>
            </thead>
            <tbody v-if="categories && categories.count && categories.data">
              <tr
                v-for="cate in categories.data"
                :key="cate.id"
                class="mt-2 mb-2"
                style="cursor: pointer;"
                :class="cate.status === 1 ? '' : 'table-tr'"
                @click="onEditCate(cate)"
              >
                <td
                  class="robo-16-400 pl-3"
                  data-title="Tên loại"
                >
                  {{cate.name}}
                </td>
                <td
                  class="robo-16-400"
                  data-title="Trạng thái"
                >
                  {{cate.status ? 'Hiển thị' : 'Ẩn'}}
                </td>
                <td
                  class="robo-16-400"
                  data-title="Ưu tiên"
                >
                  {{cate.is_prioritize ? 'Có' : 'Không'}}

                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-show="categories && categories.count"
            :items="categories"
            :current_page="page"
            @onRefresh="getNewCategory"
          ></Pagination>
        </div>
        <div
          v-if="categories && !categories.count"
          class="row"
        >
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img
              src="../../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt=""
            >
            <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy' : 'Chưa có dữ liệu!' }}</p>
          </div>
        </div>
      </div>
    </div>
    <ModalNewCategory :edit_category="edit" @refresh="refresh"/>
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
import NewNavbar from '../../../components/News/NewNavbar.vue'
import ModalNewCategory from '../../../components/News/ModalNewCategory.vue'
export default {
  name: 'NewCategoryListing',
  components: { Pagination, NewNavbar, ModalNewCategory },
  data () {
    return {
      categories: null,
      loading: false,
      search: '',
      last_page: 0,
      page: 1,
      edit: null
    }
  },
  mounted () {
    this.getNewCategory()
  },
  methods: {
    async getNewCategory () {
      let self = this
      self.loading = true
      let params = {
        page: this.page,
        limit: 15
      }
      if (self.search) {
        params.search = self.search
      }
      await self.$rf.getRequest('AdminRequest').getNewCategory(params).then((rs) => {
        self.categories = rs.data
        self.last_page = rs.data.last_page
      }).finally(() => {
        self.loading = true
      })
    },
    onEditCate (cate) {
      let self = this
      self.edit = cate
      self.modalAdd(true)
    },
    refresh () {
      this.edit = null
      this.getNewCategory()
    },
    onOpenCreate () {
      this.edit = null
      setTimeout(() => {
        this.modalAdd(true)
      }, 50)
    },
    modalAdd (show) {
      window.$('#modal_add').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
<style scoped></style>