<template>
  <div class="row">
    <div class="telemedicine-management pr-3">
      <div class="row pl-3">
        <div class="col-12 mb-3">
          <NewNavbar :name="'Bài viết'" :type="'news'"/>
        </div>
        <div class="col-sm-12">
          <div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
                <input class="form-control" type="search" placeholder="Nhập từ khoá tìm kiếm" aria-label="Search" v-model="search" @click.enter="searchNewspaper()" @click.delete="searchNewspaperDelete()">
                <button class="btn bg-pri bd-pri text-white my-sm-0" @click="searchNewspaper()">Tìm kiếm</button>
              </div>
            </div>
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex h-100">
                <button class="btn bg-pri bd-pri text-white mr-3 btn-icon-add-sm h-100" @click="onAddNewspaper()">Thêm bài viết</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr class="d-sm-flex">
                <th class="col-sm-3">Tiêu đề</th>
                <th class="col-sm-3">Mô tả ngắn</th>
                <th class="col-sm-2">Nguồn tin</th>
                <th class="col-sm-1 text-sm-center">Thứ tự</th>
                <th class="col-sm-1 text-sm-center">Trạng thái</th>
                <th class="col-sm-2">Ngày đăng</th>
              </tr>
            </thead>
            <tbody class="h-100" style="min-height: 600px;" v-if="newspapers && newspapers.data">
              <tr class="d-sm-flex mb-2 mt-3" v-for="(newspaper, index) in newspapers.data" :key="index" :class="newspaper.status === 1 ? '' : 'table-tr'" @click="showNewspaperUpdate(newspaper)" style="cursor: pointer;">
                <td class="col-sm-3" data-title="Tiêu đề">
                  <div class="flex-fill">
                    <p class="p-0 m-0">{{newspaper && newspaper.title}}</p>
                  </div>
                </td>
                <td class="col-sm-3" data-title="Mô tả ngắn">
                  <div class="line-clamp p-0 m-0">
                    <div>{{newspaper && newspaper.desc_short}}</div>
                  </div>
                </td>
                <td class="col-sm-2" data-title="Nguồn tin">
                  <span>{{newspaper && newspaper.source}}</span>
                </td>
                <td class="col-sm-1 text-sm-center" data-title="Thứ tự">
                  <span>{{newspaper && newspaper.order}}</span>
                </td>
                <td class="col-sm-1 text-sm-center" data-title="Trạng thái">
                  <span v-if="newspaper">{{newspaper.status === 1 ? 'Active' : 'InActive'}}</span>
                </td>
                <td class="col-sm-2" data-title="Ngày đăng">
                  <span>{{newspaper && newspaper.publish_at ? formatDDMMYYYY(newspaper.publish_at) : ''}}</span>
                </td>
              </tr>
              <tr v-if="newspapers && !newspapers.count">
                <td colspan="5" class="text-center">Không có bài viết</td>
              </tr>
            </tbody>
          </table>
          <Pagination v-show="newspapers && newspapers.count" :items="newspapers" :current_page="current_page" @onRefresh="getNewspapers"></Pagination>
        </div>
      </div>
    </div>
    <ModalNewspaper @onSubmitAdd="onSubmitAdd" :newspaper="newspaper" @onSubmitUpdate="onSubmitUpdate"></ModalNewspaper>
  </div>
</template>
<script>
  // import appUtils from '../../../utils/appUtils'
  import ModalNewspaper from '../../../components/News/ModalNewspaper'
  import Pagination from '../../../components/Pagination'
  import NewNavbar from '../../../components/News/NewNavbar.vue'
  export default {
    name: 'NewspaperListing',
    components: {ModalNewspaper, Pagination, NewNavbar},
    data () {
      return {
        loading: false,
        search: '',
        current_page: 1,
        page: 1,
        newspapers: null,
        newspaper: null
      }
    },
    mounted () {
      let self = this
      self.getNewspapers()
    },
    methods: {
      async getNewspapers (num) {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: num || 20,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc'
          }
          const result = await self.$rf.getRequest('AdminRequest').getNews(self.params)
          self.newspapers = result.data
        } catch (e) {
          console.log(e)
        }
      },
      showNewspaperUpdate (newspaper) {
        let self = this
        self.newspaper = newspaper
        self.showNewspaperModel(true)
      },
      showNewspaperModel (is_show) {
        window.$('#modal--newspaper').modal(is_show ? 'show' : 'hide')
      },
      searchNewspaper () {
        let self = this
        self.getNewspapers()
      },
      searchNewspaperDelete () {
        let self = this
        if (self.search.length === 0) {
          self.getNewspapers()
        }
      },
      formatDDMMYYYY (date_time) {
        return window.moment(date_time).format('DD/MM/YYYY')
      },
      onAddNewspaper () {
        let self = this
        self.newspaper = null
        self.showNewspaperModel(true)
      },
      async onSubmitAdd (data) {
        let self = this
        try {
          // statements
          await self.$rf.getRequest('AdminRequest').postNews(data).then(res => {
            self.getNewspapers()
            self.showNewspaperModel(false)
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      async onSubmitUpdate (id, data) {
        let self = this
        try {
          // statements
          await self.$rf.getRequest('AdminRequest').postNewsUpdate(id, data).then(res => {
            self.getNewspapers()
            self.showNewspaperModel(false)
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped>
.flex-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  height: 20px;
}
</style>