<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person_disease" :person="person_disease && person_disease.person" :disease="person_disease" :monitor_stats="monitor_stats"></PersonProfile>
        </div>
        <TypeBar v-if="person_disease" :showing="show" :person_id="person_disease.person_id" :disease="person_disease" @showStartMedicine="showStartMedicine"/>
        <MedicineTable></MedicineTable>
        <StartMedModal :person_diseases_id="$route.params.id"></StartMedModal>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
// import appUtils from '../../utils/appUtils'
import MedicineTable from '../../components/Diseases/MedicineTable.vue'
import StartMedModal from '../../components/Medicine/StartMedModal.vue'
export default {

  name: 'MedicineCarePlan',
  components: { PersonProfile, HeaderWindow, TypeBar, MedicineTable, StartMedModal },
  data () {
    return {
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'medicines',
      title: 'Thông tin thuốc',
      person_disease: null,
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    },
    showStartMedicine (s) {
      window.$('#modal-start-med').modal(s ? 'show' : 'hide')
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>